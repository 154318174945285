/**
 * @description 采购单状态
 */
// import { PURCHASE_STATE } from '@/constant/task';

export default function (state, check_type) {
    switch (state) {
        case 0:
        case 1:
        case 2:
        case 3:
            return '待签约';
        case 4:
            return '待签约';
        case 5:
            return check_type !== 0 ? '交付中' : '设计中';
        case 6:
            return '已完成';
    }
}
