/*
    登录和刷新  Basic
    其他接口 Bearer
*/
import request from '../utils/request';

export function getRedirectUrl(key) {
    return request({
        url: '/v1/common/auth/pre',
        method: 'get',
        params: { key },
    });
}

export function login(state, code, key) {
    return request({
        url: '/v1/common/auth/login',
        method: 'post',
        data: {
            state,
            code,
            key,
        },
        headers: {
            Authorization: 'Basic ' + process.env.VUE_APP_CLIENT_BASIC,
        },
    });
}

export function logout() {
    return request({
        url: '/v1/common/auth/logout',
        method: 'get',
    });
}

// 刷新 token
export function postRefreshToken(refresh_token) {
    return request({
        url: '/v1/common/auth/refresh',
        method: 'post',
        data: {
            refresh_token,
        },
        headers: {
            Authorization: 'Basic ' + process.env.VUE_APP_CLIENT_BASIC,
        },
    });
}
