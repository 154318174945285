import {
    setToken,
    removeToken,
    setRefreshToken,
    removeRefreshToken,
    setExpire,
    removeExpire,
} from '@/utils/auth';
import { login, logout, postRefreshToken } from '@/api/auth';

export default {
    state: {
        token: '',
        refreshToken: '',
        info: null,
        expire: null,
    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token;
        },
        SET_REFRESH_TOKEN: (state, refreshToken) => {
            state.refreshToken = refreshToken;
        },
        SET_INFO: (state, info) => {
            state.info = info;
        },
        SET_EXPIRE: (state, expire) => {
            state.expire = expire;
        },
    },
    actions: {
        /*
            路由守卫判断当前无token，则跳转 admin 平台执行登录。
            登录成功后返回 state 和 code，然后和 之前的 key 一起执行登录
            Login 只执行登录操作
            用户信息单独执行 GetUserInfo 进行操作
        */
        Login: ({ commit }, { state, code }) => {
            return new Promise((resolve, reject) => {
                const key = localStorage.getItem(process.env.VUE_APP_LOGIN_KEY);
                login(state, code, key).then(([response, error]) => {
                    if (!error) {
                        const { access_token: token, refresh_token: refreshToken } = response.data;

                        setToken(token);
                        setRefreshToken(refreshToken);
                        commit('SET_TOKEN', token);
                        commit('SET_REFRESH_TOKEN', refreshToken);

                        // token 过期时间24小时，提前5分钟就认为需要刷新token
                        const expire = Date.now() + 864e5 - 5 * 60 * 1000;
                        setExpire(expire);
                        commit('SET_EXPIRE', expire);

                        resolve(token);
                    } else {
                        reject(error);
                    }
                });
            });
        },

        // 退出登录
        Logout({ commit }) {
            logout().then(([response, error]) => {
                if (!error) {
                    removeToken();
                    removeRefreshToken();
                    removeExpire();

                    commit('SET_TOKEN', '');
                    commit('SET_REFRESH_TOKEN', '');
                    commit('SET_INFO', null);
                    commit('SET_EXPIRE', '');
                    location.href = response.data.url;
                }
            });
        },
        // 刷新 TOKEN
        RefreshToken: ({ commit, state }) => {
            return new Promise((resolve, reject) => {
                postRefreshToken(state.refreshToken).then(([response, error]) => {
                    if (!error) {
                        const { access_token: token, refresh_token: refreshToken } = response.data;

                        setToken(token);
                        setRefreshToken(refreshToken);
                        commit('SET_TOKEN', token);
                        commit('SET_REFRESH_TOKEN', refreshToken);
                        resolve(token);
                    } else {
                        reject(error);
                    }
                });
            });
        },
    },
};
