/**
 * @description 招募相关路由
 */
export default [
    {
        path: '/opus/upload',
        name: 'opus-upload',
        component: () => import(/* webpackChunkName: "opus-upload" */ '@/views/opus/upload.vue'),
        meta: {
            title: '上传作品',
        },
    },
];
