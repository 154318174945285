import axios from 'axios';
import Vue from 'vue';
import store from '@/store';

const APP_HOST = process.env.VUE_APP_API;

const service = axios.create({
    baseURL: APP_HOST,
    timeout: 10000,
});

service.interceptors.request.use(
    async (config) => {
        // 根据时间检测 token 是否过期
        const expire = store.getters.expire;
        const now = Date.now();
        if (config.url !== '/v1/auth/refresh' && expire && now > expire) {
            await store.dispatch('RefreshToken');
        }

        config.headers['Content-Type'] = 'application/json;charset=utf-8';
        if (config.method === 'post') {
            config.data = JSON.stringify(config.data);
        }
        // 注入 token
        if (!config.headers?.Authorization && store.getters.token) {
            config.headers.Authorization = 'Bearer ' + store.getters.token;
        }
        return config;
    },
    (err) => {
        return [null, err];
    }
);

service.interceptors.response.use(
    (response) => {
        if (response.data.code === 300000) {
            return [response.data, null];
        } else if (response.data.code === 300004) {
            Vue.prototype.$lkConfirm({
                message: '登录凭证已过期，确定刷新页面',
                type: 'warning',
                onConfirm: (done) => {
                    store
                        .dispatch('RefreshToken')
                        .then(() => {
                            location.reload();
                        })
                        .catch(() => {
                            store.dispatch('Logout');
                            done();
                        });
                },
            });
        } else {
            return [null, response.data];
        }
    },
    (err) => {
        return [null, err];
    }
);

export default service;
