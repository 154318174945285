import router from './router/index';
import { getToken, getRefreshToken } from '@/utils/auth';
import store from './store';
import { Message } from 'element-ui';

const WHITE_LIST = ['/login', '/callback/contract'];

router.beforeEach(async (to, from, next) => {
    const token = getToken();
    const refreshToken = getRefreshToken();
    if (token) {
        store.commit('SET_TOKEN', token); // 将 token 存入 vuex
        store.commit('SET_REFRESH_TOKEN', refreshToken); // 将 refreshToken 存入 vuex

        if (to.path === '/auth/callback') {
            next('/');
        } else {
            if (to?.meta?.title) {
                document.title = to.meta.title;
            }
            next();
        }
    } else {
        // 如果当前路径时 `/auth/callback` 则表明当前正在进行登录
        if (to.path === '/auth/callback') {
            const { state, code } = to.query;
            // 根据 state 和 code 进行登录
            store
                .dispatch('Login', { state, code })
                .then(() => {
                    // 根据登录获得的 token, 获取用户信息
                    next(localStorage.getItem(process.env.VUE_APP_TARGET_URL_KEY));
                })
                .catch((error) => {
                    Message({
                        type: 'error',
                        message: error.msg,
                    });
                });
        } else {
            if (WHITE_LIST.indexOf(to.path) > -1) {
                next();
            } else {
                // 当前无 token，也不是 admin 系统登录会跳，则跳转登录页面。
                next(`/login?from=${location.pathname + location.search}`);
            }
        }
    }
});
