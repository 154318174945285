<template>
    <div class="app-wrapper">
        <header class="app-header">
            <the-header></the-header>
        </header>
        <div class="main-container">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import theHeader from './theHeader.vue';

export default {
    name: 'layout',
    components: {
        theHeader,
    },
};
</script>

<style lang="scss" scoped>
.app-wrapper {
    .app-header {
        width: 100%;
        height: 60px;
        background: #fff;
    }
    .main-container {
        width: 1190px;
        margin: 0 auto;
        padding-top: 30px;
    }
}
</style>
