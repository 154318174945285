import Vue from 'vue';
import VueRouter from 'vue-router';

import layout from '@/layout/layout';

import task from './modules/task';
import center from './modules/center';
import recruit from './modules/recruit';
import opus from './modules/opus';
import callback from '@/router/modules/callback';

import NotFoundPage from '@/views/404.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: 'center',
        component: layout,
        children: [
            ...task,
            ...center,
            ...recruit,
            ...opus,
            ...callback,
            {
                path: '/404',
                component: NotFoundPage,
            },
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "home" */ '@/views/login.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
