import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import 'normalize.css';
import './styles/index.scss';
import './styles/element-variable.scss';

import './filters'; // 注入过滤器

import './utils/useFillOssPath'; // 注入补全资源地址为 oss 路径的方法
import './utils/useConfirm';

import './permission';

import {
    Button,
    Link,
    Message,
    Upload,
    Image,
    Dialog,
    Form,
    Radio,
    RadioGroup,
    Checkbox,
    CheckboxGroup,
    FormItem,
    Loading,
    Input,
    MessageBox,
    DatePicker,
    Select,
    Option,
    Pagination,
    Tag,
    Alert,
    Tabs,
    TabPane,
    Progress,
} from 'element-ui';

Vue.component(Button.name, Button);
Vue.component(Link.name, Link);
Vue.component(Upload.name, Upload);
Vue.component(Image.name, Image);
Vue.component(Dialog.name, Dialog);
Vue.component(Form.name, Form);
Vue.component(Radio.name, Radio);
Vue.component(RadioGroup.name, RadioGroup);
Vue.component(Checkbox.name, Checkbox);
Vue.component(CheckboxGroup.name, CheckboxGroup);
Vue.component(FormItem.name, FormItem);
Vue.component(Input.name, Input);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(Pagination.name, Pagination);
Vue.component(Tag.name, Tag);
Vue.component(Alert.name, Alert);
Vue.component(Tabs.name, Tabs);
Vue.component(TabPane.name, TabPane);
Vue.component(Progress.name, Progress);

Vue.use(Loading.directive);
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$msgbox = MessageBox;

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
