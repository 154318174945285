export default [
    {
        path: '/task/:taskNo',
        name: 'task',
        component: () => import(/* webpackChunkName: "task" */ '@/views/task/detail.vue'),
        meta: {
            title: '我的项目',
        },
    },
];
