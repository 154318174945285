/**
 * @description 二次封装 element-ui 底层的 msgbox
 */
import { MessageBox } from 'element-ui';
import Vue from 'vue';
Vue.prototype.$lkConfirm = function (options = {}) {
    const defaultOptions = {
        title: '提示',
        type: 'info', // success / info / warning / error
        confirmButtonText: '正在提交...',
    };

    options = { ...defaultOptions, ...options };
    return MessageBox({
        title: options.title,
        type: options.type,
        message: options.message,
        beforeClose(action, instance, done) {
            if (options.onConfirm && action === 'confirm') {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = options.confirmButtonText;
                options.onConfirm(() => {
                    instance.confirmButtonLoading = false;
                    done();
                });
            } else {
                if (!instance.confirmButtonLoading) done();
            }
        },
    });
};
