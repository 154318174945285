export default [
    {
        path: '/callback/contract',
        name: 'callback-contract',
        component: () =>
            import(/* webpackChunkName: "callback" */ '@/views/callback/ContractCallback.vue'),
    },
    {
        path: '/callback/auth',
        name: 'callback-auth',
        component: () =>
            import(/* webpackChunkName: "callback" */ '@/views/callback/UserSignCallback.vue'),
    },
];
