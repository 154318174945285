/**
 * @param {String | Number | Date} data 要格式化的时间，如时间戳，日期
 * @param {String} format 要转换的日期格式，如 ‘yyyy-MM-dd hh:mm:ss’
 * */
export function dateFormat(date, format) {
    if (!date) return '';
    if (typeof date === 'number' || (typeof date === 'string' && !isNaN(Number(date)))) {
        // 时间戳
        date = new Date(date);
    } else if (typeof date === 'string') {
        // safari 兼容时间格式
        const str = date.replace(/-/g, '/');
        // 字符串类型时间
        date = new Date(str);
    }

    const f = {
        'y+': date.getFullYear(),
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds(),
    };
    let fd;
    for (let o in f) {
        if (new RegExp(o).test(format)) {
            fd = RegExp.lastMatch;
            format = format.replace(
                fd,
                o === 'y+'
                    ? f[o].toString().slice(-fd.length)
                    : fd.length === 1
                    ? f[o]
                    : (f[o] + 100).toString().slice(-fd.length)
            );
        }
    }
    return format;
}

/**
 * 服务端存储的时间格式为 yyyy-MM-dd hh:mm:ss 此格式在 safari 无法解析。此函数将时间字符串转换为时间戳
 * @param {String} data 要格式化的时间文字，
 * */
export function strToTimestamp(dateStr) {
    const str = dateStr.replace(/-/g, '/');
    return new Date(str).getTime();
}

/**
 * 获取 URL 中的文件名
 * @param {String} url 被解析的url
 */
export function formatFileName(url) {
    if (!url) return '';
    const arr = url.split('/');
    let fileName = arr[arr.length - 1];
    // 去除文件名前面添加的防重复标识
    fileName = fileName.replace(/^\d{13}-/, '');
    return decodeURI(fileName);
}

/**
 * 替换文本中的空白字符为下划线或者指定字符
 * @param { string } target 目标字符串
 * @param { string } chars 替换成什么字符
 * @return {string} result
 */
export function replaceWhiteSpace(target, chars = '_') {
    return target.replace(/\s/g, chars);
}
