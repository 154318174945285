/**
 * 任务相关常量
 */
export const PURCHASE_STATE = {
    0: '待采购',
    1: '待审核',
    2: '已审核',
    3: '待签约',
    4: '待启动',
    5: '设计中',
    6: '已完成',
};

/**
 * 签订协议状态
 */
export const PROTOCOL_STATE = {
    0: '待审核',
    1: '审核中',
    2: '已驳回',
    3: '已通过',
    4: '待签约',
    5: '已签约',
};
/*
    交付物状态
    邦女郎系统状态对应如下
    0: '待提交',
    1: '待审核',
    2: '邦女郎通过',
    3: '邦女郎驳回',
    4: '大邦审核通过',
    5: '大邦审核驳回',
    6: '已交付',
*/

// 1 和 2 没写错，就这样。
export const DELIVER_STATUS = {
    0: '待提交',
    1: '平台审核中',
    2: '平台审核中',
    3: '审核未通过',
    4: '客户确认中',
    5: '审核未通过',
    6: '已交付',
};

// 提案子项状态
export const PROPOSAL_ITEM_STATE = {
    0: '待提交',
    1: '平台审核中', // 待审核
    2: '客户确认中', // 审核通过
    3: '审核未通过',
    4: '已确认',
    5: '未入选',
};
