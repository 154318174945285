export default [
    {
        path: '/center',
        name: 'center',
        redirect: '/center/apply',
        component: () => import(/* webpackChunkName: "center" */ '@/views/center/main.vue'),
        meta: {
            title: '我的项目',
        },
        children: [
            {
                path: 'apply',
                name: 'center-apply',
                component: () =>
                    import(/* webpackChunkName: "center" */ '@/views/center/apply.vue'),
            },
            {
                path: 'design',
                name: 'center-design',
                component: () =>
                    import(/* webpackChunkName: "center" */ '@/views/center/design.vue'),
            },
        ],
    },
];
