<template>
    <div class="header-content" style="text-align: right; line-height: 60px">
        <el-button type="text" @click="handleLogout">退出登录</el-button>
    </div>
</template>

<script>
import store from '../store';
export default {
    name: 'the-header',
    methods: {
        handleLogout() {
            store.dispatch('Logout');
        },
    },
};
</script>

<style lang="scss" scoped>
.header-content {
    width: 1190px;
    margin: 0 auto;
}
</style>
