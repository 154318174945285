/**
 * @description 招募相关路由
 */
export default [
    {
        path: '/recruit/:id',
        name: 'recruit-detail',
        component: () => import(/* webpackChunkName: "recruit" */ '@/views/recruit/detail.vue'),
        meta: {
            title: '招募详情',
        },
    },
    {
        path: '/recruit/apply/:id',
        name: 'recruit-apply',
        component: () => import(/* webpackChunkName: "recruit" */ '@/views/recruit/apply.vue'),
        meta: {
            title: '报名',
        },
    },
];
